import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Backdrop,
  Button,
  Card,
  Fade,
  Modal,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import { CustomerForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import addressToString from '../../utils/functions/addressToString';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '95vh',
  width: '80vw',
  boxShadow: 24,
  p: 2,
  overflowY: 'scroll',
};

const defaultValues = {
  firstname: '',
  lastname: '',
  type: '',
};

function Customers({ title, subtitle }) {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const customerType = useSelector((state) => state.customerType);
  const history = useHistory();
  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    reset(defaultValues);
  };
  const handleCSVOpen = () => setCsvUploadOpen(true);
  const handleCSVClose = () => {
    setCsvUploadOpen(false);
    setCsvData([]);
  };

  useEffect(() => {
    dispatch(actions.customerAll({ name, page, size }));
    dispatch(actions.customerTypeAll({}));
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setTotal(customer?.total);
    return () => {};
  }, [customer]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const onSubmit = async (data, event) => {
    try {
      console.log('data', data);
      event.preventDefault();
      await dispatch(actions.customerCreate(data));
      reset(defaultValues);
      alert('สำเร็จ');
      handleClose();
      await dispatch(actions.customerAll({ name, page, size }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddFromFile = async () => {
    if (!_.isEmpty(csvData)) {
      try {
        await dispatch(actions.customerCreate({ arr: csvData }));
        alert('สำเร็จ');
        handleCSVClose();
        await dispatch(actions.customerAll({ name, page, size }));
      } catch (error) {
        alert('เพิ่มไม่สำเร็จ');
        console.error(error);
      }
    } else {
      alert('ไม่สามารถอ่านไฟล์รายการได้');
    }
  };

  const handleDeleteCustomer = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.customerDelete(id));
        await dispatch(actions.customerAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePushToEditCustomer = (id) => {
    history.push(`customer/edit/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderAddButtom = () => (
    <div className="flex justify-end gap-2 pb-4">
      <Button variant="contained" onClick={handleOpen}>
        เพิ่ม
      </Button>
      <Button variant="contained" onClick={handleCSVOpen} color="success">
        อัพโหลด
      </Button>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-4xl">
          <div className="py-2">เพิ่มข้อมูล</div>
          <div className="py-2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <CustomerForm
                Controller={Controller}
                control={control}
                errors={errors}
                customerType={customerType}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
                <Button variant="outlined" onClick={handleClose}>
                  ยกเลิก
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ชื่อบริษัท</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">เบอร์โทร</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">อีเมล</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ผู้ติดต่อ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">เครดิต</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ประเภท</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(customer.rows) ? (
                customer.rows.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <div className="font-semibold">
                        {`${row.name ? row.name : '-'}` +
                          `(${row.short ? row.short : '-'})`}
                      </div>
                      <div>{addressToString(row.address)}</div>
                    </TableCell>
                    <TableCell>{`${
                      row.telephone ? row.telephone : '-'
                    }`}</TableCell>
                    <TableCell>{`${row.email ? row.email : '-'}`}</TableCell>
                    <TableCell>
                      {_.map(row?.contact, (contact) => (
                        <div key={contact._id}>
                          <p className="font-bold text-base">
                            {`${contact?.firstname} ${contact?.lastname} \n`}
                          </p>
                          <p>{`ตำแหน่ง: ${
                            contact?.position ? contact?.position : '-'
                          }`}</p>
                          <p>{`เบอร์โทร: ${
                            contact?.telephone ? contact?.telephone : '-'
                          }`}</p>
                          <p>{`อีเมล: ${
                            contact?.email ? contact?.email : '-'
                          }`}</p>
                        </div>
                      ))}
                    </TableCell>
                    <TableCell>{`${
                      row?.credit_term ? row?.credit_term : '-'
                    }`}</TableCell>
                    <TableCell>{`${row?.type?.name}`}</TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'warning'}
                          size={'small'}
                          onClick={() => handlePushToEditCustomer(row._id)}
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDeleteCustomer(row?._id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (customer.isLoading) {
    return <Loading />;
  }
  if (!customer.isLoading && customer.isCompleted) {
    return (
      <div>
        {renderModal()}

        <div className="flex justify-between">
          {renderTitle()}
          <div className="self-center">{renderAddButtom()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}
Customers.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Customers.defaultProps = {
  title: '',
  subtitle: '',
};

export default Customers;
