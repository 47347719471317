import { ViewTitle } from 'components/ViewTitle';
import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
import {
  Box,
  Button,
  Card,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useForm, Controller } from 'react-hook-form';
import AddpayoutForm from '../../components/Forms/AddpayoutForm';
import _ from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ImageUpload from '../../components/ImageUpload/ImageUpload';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',

  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
};

export default function ExpensesDetail({ title, subtitle }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const expenses = useSelector((state) => state.expenses);
  const me = useSelector((state) => state.me);
  const payout = useSelector((state) => state.payout);
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(new Date());
  const [paidType, setPaidType] = useState('TRANSFER');
  const [addedImage, setAddedImage] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [bigImage, setBigImage] = useState('');

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  useEffect(() => {
    dispatch(actions.expensesGet(id));
    dispatch(actions.payoutAll({ ex: id }));
    return () => {};
  }, []);

  const onSubmit = async (data) => {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      const dataSubmit = {
        ...data,
        paidType,
        date,
        employee: me?.userData?._id,
        expenses: id,
        budget: expenses?.budget?._id,
      };
      await dispatch(actions.payoutCreate(dataSubmit));
      await dispatch(actions.payoutAll({ ex: id }));
      dispatch(actions.expensesPut(id, { status: 'SUCCESS' }));
      dispatch(actions.expensesAll({}));
      reset();
      handleClose();
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenModal = (image) => {
    setOpenModal(true);
    setBigImage(image);
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleDelete = async (id) => {
    const confirm = window.confirm('ลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.expensesDelete(id));
        dispatch(actions.expensesAll({}));
      } catch (error) {}
    }
  };
  const handleDeletePay = async (id) => {
    const confirm = window.confirm('ลบข้อมูล');
    if (confirm) {
      await dispatch(actions.payoutDelete(id));
      await dispatch(actions.payoutAll({ ex: id }));
    }
  };

  const handleEdit = (id) => {
    history.push(`/finance/expenses/edit/${id}`);
  };

  const renderDetail = () => (
    <Card>
      <div className="p-4">
        <div className="flex gap-2">
          <h2 className="font-bold lg:text-xl ">
            Expense Detail: {expenses?.code}{' '}
          </h2>
          <div>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => handleEdit(e._id)}
            >
              <ModeEditIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => handleDelete(e._id)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
        <p1>Project : {expenses?.project?.name}</p1>
        <div>Invoice Number : {expenses?.invoice_number || '-'}</div>
        <div>Customer : {expenses?.customer?.name || '-'}</div>
        <div>
          Budget : {expenses?.budget?.prefix}
          {expenses?.budget?.budget_number} {expenses?.budget?.name}
        </div>
        <div>Date : {dayjs(expenses?.date).format('DD/MM/YYYY')}</div>
        <div>order</div>
        <div className="px-2">{expenses?.name}</div>
        <div className="">
          จำนวนเงิน{' '}
          {expenses?.price?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
        </div>
        <div>
          Applicant : {expenses?.employee?.firstname}{' '}
          {expenses?.employee?.lastname}{' '}
        </div>
      </div>
    </Card>
  );
  const handleSaveImage = async () => {
    const confirm = window.confirm('บันทึกข้อมูล');
    const data = {};
    if (confirm) {
      if (!_.isEmpty(addedImage)) {
        // eslint-disable-next-line no-param-reassign
        data.image = {
          image: addedImage[0]?.data_url,
          imageType: 'profile',
          alt: '',
        };
      }
      await dispatch(actions.expensesPut(id, data));
      setAddedImage([]);
      await dispatch(actions.expensesGet(id));
    }
  };

  const renderModal = (image) => (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {console.log(bigImage)}
        <img
          src={bigImage}
          alt="Big Image"
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
      </Box>
    </Modal>
  );

  const renderBillImage = () => (
    <div className="p-2 ">
      <div className="text-xl">
        Bill
        {expenses?.bill_image && (
          <div onClick={() => handleOpenModal(expenses.bill_image.url)}>
            <img
              src={expenses.bill_image.url}
              alt="Bill Image"
              style={{ maxWidth: '20%', height: 'auto' }}
            />
          </div>
        )}
        <div className="py-2">
          <ImageUpload
            preview_size="250"
            maxNumber={1}
            images={addedImage}
            setImages={setAddedImage}
            title={'upload'}
          />
          <div>
            {!_.isEmpty(addedImage) && (
              <div className="flex justify-center">
                <Button variant="contained" onClick={() => handleSaveImage()}>
                  save
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const renderPayment = () => (
    <div>
      <div className="p-2 flex ">
        <div className="text-xl">Payment</div>
        <div className="px-2">
          <Button size="small" variant="contained" onClick={() => handleOpen()}>
            Add payment
          </Button>
        </div>
      </div>

      {!_.isEmpty(payout?.rows) ? (
        _.map(payout?.rows, (e) => (
          <div className="px-1 py-1">
            <Card>
              <div className="flex">
                <div className="p-2">
                  <div>Payment Date {dayjs(e?.date).format('DD/MM/YYYY')}</div>
                  <div>
                    Paid{' '}
                    {e?.price?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                  </div>
                  <div>Method : {e?.paidType}</div>
                  <div>Remark : {e?.remark || '-'}</div>
                </div>
                <div className="py-1">
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => handleDeletePay(e._id)}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </div>
              </div>
            </Card>
          </div>
        ))
      ) : (
        <div className="px-1 py-1">
          <Card>
            <div className="p-2">Waiting for payment</div>
          </Card>
        </div>
      )}
    </div>
  );

  const renderModalAddPayment = () => (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
          ></Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <AddpayoutForm
              control={control}
              Controller={Controller}
              date={date}
              setDate={setDate}
              paidType={paidType}
            />
          </form>
        </Box>
      </Modal>
    </div>
  );

  return (
    <div>
      {renderTitle()}
      {renderModalAddPayment()}
      {renderModal()}
      <div>{renderDetail()}</div>
      <div className="py-2">{renderBillImage()}</div>
      <div className="py-2">{renderPayment()}</div>
    </div>
  );
}
