import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ImageUpload from '../ImageUpload/ImageUpload';

export function EmployeeForm({
  errors,
  roletype,
  employee,
  Controller,
  control,
  addUser,
  setAddUser,
  employeeImage,
  setEmployeeImage,
  department,
}) {
  return (
    <div className="flex flex-row flex-wrap">
      {addUser != null ? (
        <div className="w-full px-1 py-1">
          <FormControlLabel
            value="end"
            defaultValue={addUser}
            control={<Checkbox color="primary" />}
            label="สร้างพนักงานพร้อมบัญชีผู้ใช้"
            labelPlacement="end"
            name="addUser"
            onChange={() => {
              setAddUser(!addUser);
            }}
          />
        </div>
      ) : (
        <></>
      )}

      {addUser === true ? (
        <>
          <div className="w-full px-1 py-1">
            <Controller
              name={'username'}
              control={control}
              defaultValue={employee ? employee.username : ''}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อผู้ใช้"
                  fullWidth
                  size={'small'}
                  required
                  helperText={errors.username && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="flex w-full">
            <div className="w-1/2 px-1 py-1">
              <Controller
                name={'password'}
                control={control}
                defaultValue={employee ? employee.password : ''}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="รหัสผ่าน"
                    type="password"
                    fullWidth
                    size={'small'}
                    required
                    helperText={errors.password && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
            <div className="w-1/2 px-1 py-1">
              <Controller
                name={'confirmPassword'}
                control={control}
                defaultValue={employee ? employee.password : ''}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ยืนยันรหัสผ่าน"
                    type="password"
                    fullWidth
                    size={'small'}
                    required
                    helperText={errors.password && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="w-1/2 px-1 py-1">
        <Controller
          name={'firstname'}
          control={control}
          defaultValue={employee ? employee.firstname : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="firstname"
              fullWidth
              size={'small'}
              required
              helperText={errors.firstname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-1">
        <Controller
          name={'lastname'}
          control={control}
          defaultValue={employee ? employee.lastname : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="lastname"
              fullWidth
              size={'small'}
              required
              helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-1">
        <Controller
          name={'firstname_th'}
          control={control}
          defaultValue={employee ? employee.firstname_th : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อภาษาไทย"
              fullWidth
              size={'small'}
              required
              helperText={errors.firstname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-1">
        <Controller
          name={'lastname_th'}
          control={control}
          defaultValue={employee ? employee.lastname_th : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="นามสกุลภาษาไทย"
              fullWidth
              size={'small'}
              required
              helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'phone_number'}
          control={control}
          defaultValue={employee ? employee.phone_number : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="telephone"
              fullWidth
              size={'small'}
              required
              helperText={errors.phone_number && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'salary.month'}
          control={control}
          defaultValue={employee ? employee?.salary?.month : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="salary"
              fullWidth
              size={'small'}
              type="number"
              required
              helperText={errors.phone_number && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'department'}
          control={control}
          defaultValue={employee ? employee?.department?._id : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl required sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                department
              </InputLabel>
              <Select {...field} label="department" size={'small'} fullWidth>
                {_.size(department?.rows) ? (
                  _.map(department.rows, (row) => (
                    <MenuItem key={row._id} value={row._id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>

      <div className="w-full px-1 py-2">
        <Controller
          name={'role'}
          control={control}
          defaultValue={employee ? employee?.role?.id : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth="true" required>
              <InputLabel id="type" size={'small'}>
                role
              </InputLabel>
              <Select {...field} label="role" size={'small'} fullWidth>
                {_.size(roletype?.rows) ? (
                  _.map(roletype.rows, (row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>

      {employee?.image && (
        <div className="w-full px-1 py-2 ">
          <img src={employee?.image?.url} className="h-64" />
        </div>
      )}

      <div className="w-full px-1 py-2">
        <ImageUpload
          preview_size="250"
          maxNumber={1}
          images={employeeImage}
          setImages={setEmployeeImage}
          title={employee?.rows ? 'อัพโหลดรูปภาพ' : 'แก้ไขรูปภาพ'}
        />
      </div>
    </div>
  );
}

EmployeeForm.propTypes = {
  roletype: PropTypes.object,
  errors: PropTypes.shape({
    username: PropTypes.object,
    password: PropTypes.object,
    firstname: PropTypes.object,
    lastname: PropTypes.object,
    phone_number: PropTypes.object,
  }),
  employeeImage: PropTypes.arrayOf(PropTypes.object),
  setEmployeeImage: PropTypes.func.isRequired,
  addUser: PropTypes.object,
  setAddUser: PropTypes.func,
  employee: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

EmployeeForm.defaultProps = {
  employee: null,
  addUser: null,
};

export default EmployeeForm;
