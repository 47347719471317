import { FormControlLabel, TextField, Checkbox } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export function DepartmentForm({ errors, department, Controller, control }) {
  const renderAccessList = () => (
    <div className="px-2">
      <div className="w-full px-1 py-1">
        <Controller
          name={'access.PROJECT'}
          control={control}
          defaultValue={department ? department.access?.PROJECT : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="project"
            />
          )}
        />
      </div>{' '}
      <div className="w-full px-1 py-1">
        <Controller
          name={'access.CUSTOMER'}
          control={control}
          defaultValue={department ? department.access?.CUSTOMER : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="customer"
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'access.DIRECTOR'}
          control={control}
          defaultValue={department ? department.access?.DIRECTOR : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="director"
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'access.FINANCE'}
          control={control}
          defaultValue={department ? department.access?.FINANCE : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="finance"
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'access.MANAGEMENT'}
          control={control}
          defaultValue={department ? department.access?.MANAGEMENT : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="management"
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'access.PROFILE'}
          control={control}
          defaultValue={department ? department.access?.MANAGEMENT : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="profile"
            />
          )}
        />
      </div>
    </div>
  );
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={department ? department.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="name"
              required
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'description'}
          control={control}
          defaultValue={department ? department.description : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="description"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors.description && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div>
        <div>Access Module</div>
        <div>{renderAccessList()}</div>
      </div>
    </div>
  );
}

DepartmentForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
  }),
  department: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

DepartmentForm.defaultProps = {
  department: null,
};

export default DepartmentForm;
