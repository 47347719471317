import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { HomeNavbar } from '../components/Nevbars/index';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { NotFound } from '../components/Error';
// import { Loading } from '../components/Loading';
// import accessRight from '../utils/functions/accessRight';
import { CreateDropletProject } from './Monitor';
import * as actions from '../redux/actions';
import Loading from 'components/Loading';
import { Card } from '@mui/material';
import { DollarSign, Clock } from 'react-feather';

export default function Home() {
  const dispatch = useDispatch();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const me = useSelector((state) => state.me);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };
  useEffect(() => {
    dispatch(actions.meGet());

    return () => {};
  }, []);
  if (!me.userData) {
    return <Loading />;
  }
  return (
    <div
      className="min-h-screen"
      // style={{
      //   backgroundImage: `url(${process.env.PUBLIC_URL}/your-image-name.jpg)`, // replace with your image URL
      //   backgroundSize: 'cover',
      //   backgroundPosition: 'center',
      // }}
    >
      <MainSidebar
        onMobileClose={handleOnMobileNavClose}
        openMobile={isMobileNavOpen}
        me={me}
      />
      <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
      <div className=" flex flex-col justify-center items-center min-h-screen">
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4   ">
          <Link to={'/profile/timestamp'}>
            <div className="bg-green-600  shadow rounded text-center  py-8 px-4 lg:h-40 w-80  transition-transform transform hover:scale-105">
              <div className="flex justify-center">
                <Clock size={50} color="white" />
              </div>
              <p className=" text-white mt-4">ลงเวลา</p>
            </div>
          </Link>{' '}
          <Link to={'/profile/disbursement'}>
            <div className="bg-blue-600  shadow rounded text-center  py-8 px-4 lg:h-40 w-80   transition-transform transform hover:scale-105">
              <div className="flex justify-center">
                <DollarSign size={50} color="white" />
              </div>
              <p className=" text-white mt-4">เบิกจ่าย</p>
            </div>
          </Link>{' '}
        </div>
      </div>
      <div className="lg:ml-64">
        <MainFooter />
      </div>
    </div>
  );
}
