import { ViewTitle } from 'components/ViewTitle';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
import ListTimestamp from '../../components/Card/ListTimestamp';
import Loading from 'components/Loading';

export default function Timestamp({ title, subtitle }) {
  const dispatch = useDispatch();
  const timestamp = useSelector((state) => state.timestamp);
  const me = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(actions.meGet());
    dispatch(actions.timestampAll({ me: me?.userData?._id }));

    return () => {};
  }, []);
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderButton = () => <ListTimestamp timestamp={timestamp} />;
  if (
    !timestamp?.isLoading &&
    timestamp?.isCompleted &&
    !me?.isLoading &&
    me?.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        <div>{renderButton()}</div>
      </div>
    );
  }
  return <Loading isLoading />;
}
