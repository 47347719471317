import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { ViewTitle } from 'components/ViewTitle';

import { Box, Button, Modal, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
// import expensesForm from 'components/Forms/expensesForm';
import ExpensesApplyForm from 'components/Forms/ExpensesApplyForm';
import { ExpensesTableApplove } from '../../components/Table';
import ExpensesHrApplyForm from 'components/Forms/ExpensesHrApplyForm';
import AddpayoutForm from 'components/Forms/AddpayoutForm';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',

  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
};

function ExpensesList({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const expenses = useSelector((state) => state.expenses);
  const projects = useSelector((state) => state.project);
  const customer = useSelector((state) => state.customer);
  const me = useSelector((state) => state.me);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = useState(new Date());
  const [paidType, setPaidType] = useState('TRANSFER');
  const [projectSelect, setProjectSelect] = useState('');
  const [budgetSelect, setBudgetSelect] = useState('');
  const [openPay, setOpenPay] = useState(false);
  const [datePay, setDatePay] = useState(new Date());
  const [exSelect, setExSelect] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenPay = (e) => {
    setOpenPay(true);
    setExSelect(e);
  };
  const handleClosePay = () => setOpenPay(false);

  useEffect(() => {
    dispatch(actions.expensesAll({ page, size }));
    dispatch(actions.projectAll({}));
    dispatch(actions.customerAll({}));
    dispatch(actions.meGet());

    return () => {};
  }, [page, size]);
  useEffect(() => {
    dispatch(actions.budgetAll({ project_id: projectSelect?.id }));

    return () => {};
  }, [projectSelect]);

  const filter = expenses?.rows;

  const handleDelete = async (id) => {
    const confirm = window.confirm('ลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.expensesDelete(id));
        dispatch(actions.expensesAll({}));
      } catch (error) {}
    }
  };

  const handleEdit = (id) => {
    history.push(`/finance/expenses/edit/${id}`);
  };
  const handleDetail = (id) => {
    history.push(`/finance/expenses/detail/${id}`);
  };

  const handleApprove = async (id) => {
    await dispatch(actions.expensesPut(id, { status: 'APPROVE' }));
    dispatch(actions.expensesAll({}));
  };

  const handleSuccess = async (id) => {
    await dispatch(actions.expensesPut(id, { status: 'SUCCESS' }));
    dispatch(actions.expensesAll({}));
  };
  const handleBillPickup = async (id) => {
    await dispatch(actions.expensesPut(id, { bill_pickup: true }));
    dispatch(actions.expensesAll({}));
  };

  const onSubmit = async (data) => {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      const dataSubmit = {
        ...data,
        paidType,
        date,
        employee: me?.userData?._id,
      };
      await dispatch(actions.expensesCreateWithOutNotify(dataSubmit));
      await dispatch(actions.expensesAll({}));
      reset();
      handleClose();
    }
  };

  const onSubmitPay = async (data) => {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      const dataSubmit = {
        ...data,
        paidType,
        date: datePay,
        employee: me?.userData?._id,
        expenses: exSelect?.id,
        budget: exSelect?.budget?._id,
      };
      await dispatch(actions.payoutCreate(dataSubmit));
      await dispatch(actions.payoutAll({}));
      dispatch(actions.expensesPut(exSelect?._id, { status: 'SUCCESS' }));
      dispatch(actions.expensesAll({}));
      reset();
      handleClosePay();
    }
  };

  const renderModalAddPayment = () => (
    <div>
      <Modal
        open={openPay}
        onClose={handleClosePay}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
          ></Typography>
          <form onSubmit={handleSubmit(onSubmitPay)}>
            <AddpayoutForm
              control={control}
              Controller={Controller}
              date={datePay}
              setDate={setDatePay}
              paidType={paidType}
            />
          </form>
        </Box>
      </Modal>
    </div>
  );

  const renderModal = () => (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
          ></Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ExpensesHrApplyForm
              control={control}
              Controller={Controller}
              date={date}
              setDate={setDate}
              paidType={paidType}
              setPaidType={setPaidType}
              projects={projects}
              setProjectSelect={setProjectSelect}
              projectSelect={projectSelect}
              setValue={setValue}
              budgetSelect={budgetSelect}
              setBudgetSelect={setBudgetSelect}
              customer={customer}
            />
          </form>
        </Box>
      </Modal>
    </div>
  );

  const renderTable = () => (
    <ExpensesTableApplove
      expenses={expenses}
      page={page}
      size={size}
      setPage={setPage}
      setSize={setSize}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      filter={filter}
      handleApprove={handleApprove}
      handleSuccess={handleSuccess}
      handleBillPickup={handleBillPickup}
      handleDetail={handleDetail}
      handleOpenPay={handleOpenPay}
    />
  );

  const renderAddButton = () => (
    <div className="flex justify-end py-1">
      <Button variant="contained" onClick={handleOpen}>
        Expenses
      </Button>
    </div>
  );
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  return (
    <div>
      {renderTitle()}
      {renderAddButton()}
      {renderTable()}
      {renderModal()}
      {renderModalAddPayment()}
    </div>
  );
}

ExpensesList.propTypes = {};

export default ExpensesList;
