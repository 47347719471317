import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import _ from 'lodash';

export default function ProjectForm({
  Controller,
  control,
  employee,
  date,
  setDate,
  dateEnd,
  setDateEnd,
}) {
  return (
    <div className="lg:grid grid-cols-2 gap-2 ">
      {/* <div className="flex flex-row flex-wrap"> */}
      <div className="py-1  ">
        <Controller
          name={'project_number'}
          control={control}
          defaultValue={''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="project ID"
              fullWidth
              size={'small'}
              required
            />
          )}
        />
      </div>
      <div className="py-1">
        <Controller
          name={'name'}
          control={control}
          defaultValue={''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="name"
              fullWidth
              size={'small'}
              required
            />
          )}
        />
      </div>
      <div className="py-1">
        <Controller
          name={'description'}
          control={control}
          defaultValue={''}
          render={({ field }) => (
            <TextField
              {...field}
              label="description"
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
      <div className="py-1">
        <Controller
          name={'cost'}
          control={control}
          defaultValue={''}
          render={({ field }) => (
            <TextField
              {...field}
              label="value"
              fullWidth
              type="number"
              size={'small'}
            />
          )}
        />
      </div>
      <div className="py-1">
        <Controller
          name={'customer'}
          control={control}
          defaultValue={''}
          render={({ field }) => (
            <TextField {...field} label="customer" fullWidth size={'small'} />
          )}
        />
      </div>
      <div className="py-1">
        <Controller
          name={'place'}
          control={control}
          defaultValue={''}
          render={({ field }) => (
            <TextField {...field} label="location" fullWidth size={'small'} />
          )}
        />
      </div>
      <div className="w-full py-1">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date"
            value={date}
            onChange={(newValue) => {
              setDate(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" fullWidth />
            )}
          />
        </LocalizationProvider>
      </div>
      <div className="w-full py-1">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date submit"
            value={dateEnd}
            onChange={(newValue) => {
              setDateEnd(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" fullWidth />
            )}
          />
        </LocalizationProvider>
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'engineer'}
          control={control}
          defaultValue={employee ? employee?.engineer?._id : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth="true" required>
              <InputLabel id="type" size={'small'}>
                project manager
              </InputLabel>
              <Select
                {...field}
                label="project manager"
                size={'small'}
                fullWidth
              >
                {_.size(employee) ? (
                  _.map(employee, (row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row?.firstname} {row?.lastname}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      {/* </div> */}
    </div>
  );
}
