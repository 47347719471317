import { ViewTitle } from 'components/ViewTitle';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectForm } from 'components/Forms';
import * as actions from '../../redux/actions';
import { useForm, Controller } from 'react-hook-form';
import { Button, Card } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export default function EditProject({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const project = useSelector((state) => state.project);
  const employee = useSelector((state) => state.employee);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  const [date, setDate] = useState(project?.date_start);
  const [dateEnd, setDateEnd] = useState(project?.date_end);

  useEffect(() => {
    dispatch(actions?.projectGet(id));
    dispatch(actions.employeeAll({}));

    return () => {};
  }, []);

  useEffect(() => {
    setValue('engineer', project?.engineer?._id);
    setValue('name', project?.name);
    setValue('project_number', project?.project_number);
    setValue('description', project?.description);
    setValue('customer', project?.customer);
    setValue('place', project?.place);
    setValue('cost', project?.cost);

    return () => {};
  }, [project]);

  const onSubmit = (data) => {
    const confirm = window.confirm('บันทึก');
    if (confirm) {
      const dataSubmit = {
        ...data,
        date_start: date,
        date_end: dateEnd,
      };

      dispatch(actions?.projectPut(id, dataSubmit));
      dispatch(actions?.projectGet(id));
      history.goBack();
    }
  };
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderForm = () => (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'p-2'}>
          <ProjectForm
            Controller={Controller}
            control={control}
            date={date}
            setDate={setDate}
            dateEnd={dateEnd}
            setDateEnd={setDateEnd}
            employee={employee?.rows}
          />
          <div className="flex justify-center">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </div>
      </form>
    </Card>
  );
  return (
    <div>
      {renderTitle()}
      <div>{renderForm()}</div>
    </div>
  );
}
