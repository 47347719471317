import { Button, Card } from '@mui/material';
import { ViewTitle } from 'components/ViewTitle';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import HrcheckinList from '../../components/Table/HrcheckinList';
import FilterTimestampCard from '../../components/Card/FilterTimestampCard';
export default function HrTimestampList({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const timestamp = useSelector((state) => state.timestamp);
  const employees = useSelector((state) => state.employee);
  const info = useSelector((state) => state.info);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [emSelect, setEmSelect] = useState();
  const [dateStart, setDateStart] = useState(new Date());
  const [dateEnd, setDateEnd] = useState(new Date());

  useEffect(() => {
    dispatch(actions.timestampAll({ size, page, emSelect }));
    dispatch(actions.employeeAll({}));
    dispatch(actions.getInformation());

    return () => {};
  }, [page, size, emSelect]);

  const renderCardQuery = () => (
    <div>
      <FilterTimestampCard
        employees={employees}
        emSelect={emSelect}
        setEmSelect={setEmSelect}
        dateStart={dateStart}
        dateEnd={dateEnd}
        setDateStart={setDateStart}
        setDateEnd={setDateEnd}
      />
    </div>
  );

  const renderTable = () => (
    <HrcheckinList
      timestamp={timestamp}
      page={page}
      size={size}
      setPage={setPage}
      setSize={setSize}
      show={info?.setting?.timestamp_image}
    />
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div>
      {renderTitle()}
      <div className="py-1">{renderCardQuery()}</div>
      <div>{renderTable()}</div>
    </div>
  );
}
