import React from 'react';
import {
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
export default function ExpensesApplyForm({
  control,
  Controller,
  date,
  setDate,
  setPaidType,
  paidType,
  projects,
  projectSelect,
  setProjectSelect,
  setValue,
  budgetSelect,
  setBudgetSelect,
  edit,
}) {
  const handleChange = (event) => {
    setPaidType(event.target.value);
  };

  const handleCheckLevel = (data, index) => {
    const each = _.find(projects.rows, { _id: data?._id });
    setValue(`project`, each?._id);
    setProjectSelect(each);
  };

  const handleCheckBudget = (data, index) => {
    const each = _.find(projectSelect?.budget, { _id: data?._id });
    setValue(`budget`, each?._id);
    setBudgetSelect(each);
  };

  return (
    <div>
      {edit && (
        <div className="w-full px-1 py-1">
          <Controller
            name={'code'}
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="code"
                fullWidth
                size={'small'}
                required
              />
            )}
          />
        </div>
      )}
      <div className="w-full px-1 py-1">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date"
            value={date}
            onChange={(newValue) => {
              setDate(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" fullWidth />
            )}
          />
        </LocalizationProvider>
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={`project`}
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              disablePortal
              id="free-solo-demo"
              freeSolo
              options={projects?.rows || []}
              getOptionLabel={(option) =>
                `${option.project_number} | ${option.name}`
              }
              onChange={(e, newValue) => handleCheckLevel(newValue)}
              renderInput={(params) => (
                <TextField
                  {...field}
                  {...params}
                  size="small"
                  label="Project"
                />
              )}
            />
          )}
        />{' '}
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={`budget`}
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              disablePortal
              id="free-solo-demo"
              freeSolo
              options={projectSelect?.budget || []}
              getOptionLabel={(option) =>
                `${option.prefix}${option.budget_number} | ${option.name}`
              }
              onChange={(e, newValue) => handleCheckBudget(newValue)}
              renderInput={(params) => (
                <TextField {...field} {...params} size="small" label="Budget" />
              )}
            />
          )}
        />{' '}
      </div>

      <div className="w-full px-1 py-1">
        <Controller
          name={'name'}
          control={control}
          defaultValue={''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="shopping list"
              fullWidth
              size={'small'}
              required
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'price'}
          control={control}
          defaultValue={''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="price"
              fullWidth
              type="number"
              size={'small'}
              required
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">วิธีชำระเงิน</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={paidType}
            label="วิธีชำระเงิน"
            size="small"
            onChange={handleChange}
          >
            <MenuItem value={'TRANSFER'}>โอนเงิน</MenuItem>
            <MenuItem value={'CASH'}>เงินสด</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="flex justify-center">
        <Button variant="contained" type="submit">
          บันทึก
        </Button>
      </div>
    </div>
  );
}
