export const PAID_STATUS = {
  PENDING: {
    status_code: 'PENDING',
    description: 'pending',
  },
  APPROVE: {
    status_code: 'APPROVE',
    description: 'approve',
  },
  SUCCESS: {
    status_code: 'SUCCESS',
    description: 'success',
  },
};
export default PAID_STATUS;
