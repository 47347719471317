import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function AuthFooter() {
  return (
    <>
      <footer className=" relative lg:absolute w-full bottom-0 ">
        <div className="container mx-auto px-4 bg-transparent">
          <hr className="mb-6 border-b-1 border-gray-700" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4">
              <div className="text-sm text-gray-600 font-normal py-1 text-center md:text-left">
                Copyright © {new Date().getFullYear()} , Core plane erp
                <br />
                Powered By Core plane team ver.{' '}
                {process.env.REACT_APP_VERSION_RELEASE}
              </div>
            </div>
            {/* <div className="hidden md:block md:w-8/12 px-4">
              <ul className="flex flex-wrap list-none md:justify-end  justify-center">
                <li>
                  <a
                    href="https://iarc.psu.ac.th/"
                    className="text-gray-700 hover:text-gray-900 text-sm font-normal block py-1 px-3"
                  >
                    IARC PSU
                  </a>
                </li>
                <li>
                  <a
                    href="https://iarc.psu.ac.th/about/"
                    className="text-gray-700 hover:text-gray-900 text-sm font-normal block py-1 px-3"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/iarc.psu"
                    className="text-gray-700 hover:text-gray-900 text-sm font-normal block py-1 px-3"
                  >
                    Facebook
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </footer>
    </>
  );
}
