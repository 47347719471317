import {
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import React from 'react';

import LaunchIcon from '@mui/icons-material/Launch';
import IconButton from '@mui/material/IconButton';

export default function ProjectTable({
  projects,
  handleOnclickDetail,
  page,
  setPage,
  size,
  setSize,
}) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };
  return (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow className={'bg-gray-600'}>
                <TableCell>
                  {' '}
                  <h1 className=" text-white ">ID</h1>
                </TableCell>
                <TableCell>
                  <h1 className=" text-white  ">Project name </h1>
                </TableCell>
                <TableCell>
                  <h1 className="  text-white ">Quoted Price</h1>
                </TableCell>
                <TableCell>
                  {' '}
                  <h1 className="  text-white ">Customer</h1>
                </TableCell>
                <TableCell>
                  <h1 className=" text-white  ">Status</h1>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.isEmpty(projects?.rows) ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No items
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                _?.map(projects?.rows, (e) => (
                  <TableRow
                    key={e.id}
                    style={{ height: 60 }}
                    onClick={() => handleOnclickDetail(e._id)}
                  >
                    <TableCell>{e?.project_number}</TableCell>
                    <TableCell>
                      <div className="flex">
                        <div>{e?.name}</div>
                        <IconButton
                          size="small"
                          onClick={() => handleOnclickDetail(e._id)}
                        >
                          <LaunchIcon fontSize="inherit" />
                        </IconButton>
                      </div>
                    </TableCell>
                    <TableCell>
                      {e?.cost?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </TableCell>
                    <TableCell>{e?.customer || '-'}</TableCell>
                    <TableCell>
                      <div>
                        <div>
                          {e?.deliver_status?.status ? (
                            <Chip
                              label="delivred"
                              color="success"
                              size="small"
                            ></Chip>
                          ) : (
                            <Chip
                              label="pending"
                              color="error"
                              size="small"
                            ></Chip>
                          )}
                        </div>
                        {!_.isEmpty(e?.billing) && (
                          <div className="py-1">
                            <Chip
                              label="Billing"
                              color="success"
                              size="small"
                            ></Chip>
                          </div>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 30, 40, 50, 100]}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={projects?.total || 0}
          rowsPerPage={size}
          page={page - 1}
        />
      </Paper>
    </div>
  );
}
